@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --header-height: 60px;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --primary: 217.2 32.6% 17.5%;
    --primary-foreground: 210 40% 98%;
    --secondary: 214.3 31.8% 91.4%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 217.2 32.6% 17.5%;
    --radius: 0.5rem;
    --chart-1: 198, 61%, 39%;
    --chart-2: 94, 38%, 59%;
    --chart-3: 20, 94%, 63%;
    --chart-4: 162, 43%, 46%;
    --chart-5: 208, 25%, 45%;
    --chart-6: 359, 94%, 62%;
    --chart-7: 178, 30%, 43%;
    --chart-8: 21, 89%, 56%;
    --chart-9: 33, 94%, 55%;
    --modal-delete:4,74%,49%,1;
    --modal-delete-icon:4,95%,94%,1;
    --modal-mobile-background:0, 0%, 0%, 0.3;
    --custom-gradient:#53389E;
    --background-form:#f3f3fe;

  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --modal-delete:4,74%,49%,1;
    --modal-delete-icon:4,95%,94%,1;
    --modal-mobile-background:0, 0%, 0%, 0.3;
    --custom-gradient:#53389E;
    --background-form:#f3f3fe;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.title {
  @apply font-medium leading-none tracking-tight text-lg;
}
.description{
  @apply text-sm text-muted-foreground text-sm;
}

.height-full-container {
  height: calc(100vh - var(--header-height)); 
}

.height-full-container-panels {
  height: calc(100vh - var(--header-height) - 15.5rem); 
}
.height-full-container-form {
  height: calc(100vh - var(--header-height) - 13rem); 
}
.height-full-container-flow {
  height: calc(100vh - var(--header-height) - 15rem); 
}
.height-full-container-mobile {
  height: calc(100% - 3rem); 
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
 
}
.scroll-container::-webkit-scrollbar-thumb {
  background: #94A3B8; 
  border-radius: 4px;
  cursor: pointer
}
.scroll-container::-webkit-scrollbar-track {
  background: #E5E7EB;
  ;
}

.markdown-style a {
  color: blue;
  text-decoration: underline;
}
